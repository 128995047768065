<template>
  <div id="quality-control">
    <div class="black-block mobile-only" />
    <section class="hero">
      <div class="gradient" />
    </section>
    <section class="traditions togas-container">
      <div class="traditions--text-block">
        <span class="title"> TRADITION <br> <span class="title--spacer">AND INNOVATION</span></span>
        <p class="text">Togas House of Textiles is a Greek company with a centennial history. We started our journey
          back in 1926 in
          Athens. Nowadays, we manufacture and sell our products all over the world, and constantly cover new areas.
        </p>
        <p class="text">
          For almost a century, we have been applying the best world practices in all areas of our activity, whether it
          is design, production, trade, or customer service. We at Togas do our best to make our products as
          environmentally friendly as possible and to reduce the carbon footprint down to the minimum.
        </p>
      </div>
      <div class="traditions--image-block">
        <picture class="tradition">
          <source
            media="screen and (min-width: 1024px)"
            srcset="~static/quality-control/traditional-d.jpg"
          />
          <img src="~static/quality-control/traditional-m.jpg" alt="traditional" loading="lazy" />
        </picture>
        <picture class="innovation">
          <source
            media="screen and (min-width: 1024px)"
            srcset="~static/quality-control/innovation-d.jpg"
          />
          <img src="~static/quality-control/innovation-m.jpg" alt="traditional" loading="lazy" />
        </picture>
      </div>
    </section>
    <section class="quality">
      <div class="togas-container">
        <div class="quality--wrapper">
          <span>MAIN QUALITY CONTROL GROUP</span>
          <img class="worldwide" src="~/static/quality-control/worldwide.svg" alt="worldwide" loading="lazy">
          <p class="text">
            QC Worldwide is a division of our R&D center Togas Innovations in Athens. This is where our quality
            standards
            are developed and employees are trained. After the training, they control all the stages of production at
            local
            factories.
          </p>
          <p class="text">
            Each of the 30 product groups has its high standards as well as specially selected factory locations. They
            are
            optimally suited to
            the supply chains of raw materials which are needed for the production of particular products.
          </p>
        </div>
        <picture class="logos">
          <source
            media="screen and (min-width: 1024px)"
            srcset="~static/quality-control/logos-d.png"
          />
          <img src="~static/quality-control/labels.png" alt="logos" loading="lazy" />
        </picture>
      </div>
    </section>
    <section class="factory togas-container">
      <p class="text common-text mobile-only">The selection and certification of factories that produce Togas products
        are carried
        out
        by European specialists of the QC Worldwide team in accordance with over 80 parameters.
      </p>
      <p class="text common-text desktop-only">The selection and certification of factories that produce Togas products
        are carried
        out
        by European specialists of the <strong>QC Worldwide</strong> team in accordance with over 80 parameters.
      </p>
      <p class="factory--text">On average, the process of certification of a factory takes 1.5 years.</p>
    </section>
    <section class="greece">
      <div class="image">
      </div>
      <div class="content togas-container">
        <div class="image-block ">
          <picture>
            <source
              media="screen and (min-width: 1024px)"
              srcset="~static/quality-control/greece-icon.png"
            />
            <img src="~static/quality-control/greece-icon-m.png" alt="greece icon" loading="lazy" />
          </picture>
          <span class="title">Originated<br><span class="title--spacer">in Greece</span></span>
        </div>
        <div class="text-block ">
          <p class="text">
            We have our own certified manufacturing in Athens, which produces high-quality bedding essentials and home
            textiles in accordance with all European requirements.
          </p>
          <p class="text">We invest a lot of resources in order to be confident in our partners and their
            competence.</p>
        </div>
      </div>
    </section>
    <section class="standards">
      <div class="image" />
      <div class="content togas-container">
        <div class="image-block ">
          <picture>
            <source
              media="screen and (min-width: 1024px)"
              srcset="~static/quality-control/standards-icon-d.png"
            />
            <img src="~static/quality-control/standards-icon-m.png" alt="standards icon" loading="lazy" />
          </picture>
          <span class="title">UAE World <br><span class="title--spacer">Standards</span></span>
        </div>
        <div class="text-block ">
          <p class="text">In addition, Togas also produces textiles in the UAE. Thus, we use the most efficient ways to
            deliver Togas products of unsurpassed quality to all countries where the brand is represented.
          </p>
        </div>
      </div>
    </section>
    <section class="pillow">
      <div class="pillow--wrap togas-container">
        <div class="pillow--text-wrap">
          <p class="text">
            In addition to certification, it is extremely important for us to follow the strategy of optimizing
            logistics
            processes and sustainable manufacturing. That is why we create local production in the countries where Togas
            has
            the maximum presence.
          </p>
          <div class="signature mobile-only">
            <div>
              <p>"What does always stay constant? </p>
              <p>The quality of our products, of course"</p>
            </div>
            <div class="signature--wrap">
              <img src="~static/quality-control/signature-m.png" alt="signature" loading="lazy">
              <span>Kostas Togas</span>
            </div>
          </div>
          <div class="signature desktop-only">
            <p>"What does always stay constant?</p>
            <div class="signature--wrap">
              <p>The quality of our products, of course"</p>
              <div>
                <img src="~static/quality-control/signature-m.png" alt="signature" loading="lazy">
                <span>Kostas Togas</span>
              </div>
            </div>
          </div>
        </div>
        <div class="pillow--image-wrap">
          <picture>
            <source
              media="screen and (min-width: 1024px)"
              srcset="~static/quality-control/pillow.jpg"
            />
            <img
              class="img-block"
              src="~/static/quality-control/pillow_mob.jpg" alt="pillow" loading="lazy" />
          </picture>
        </div>
      </div>
    </section>
    <section class="map togas-container">
      <div class="text-wrap">
        <p class="text">
          We work at dozens of factories in Europe, Asia, and North America in order to serve local markets, as it is
          technologically important to process many exclusive materials locally.
        </p>
        <p class="text">Togas started as a production. Over the past hundred years, we have built and developed many
          factories across Europe, Russia and Middle East.
        </p>
        <span>We know everything about it</span>
      </div>
      <div class="img-wrap">
        <img src="~static/quality-control/world-d.png" alt="map" loading="lazy">
      </div>
    </section>
    <section class="girls togas-container">
      <div class="img-wrap">
        <picture>
          <source
            media="screen and (min-width: 1024px)"
            srcset="~static/quality-control/girls-d.png"
          />
          <img src="~static/quality-control/girls-m.png" alt="traditional" loading="lazy" />
        </picture>
      </div>
      <div class="text-wrap">
        <p class="text">
          We treat our customers with the utmost respect and gratitude and work tirelessly to ensure the high quality of
          our products. They should not only give joy and a sense of comfort but also be reliable and durable.
        </p>
      </div>
    </section>
    <section class="field">
      <div class="gradient">
        <div class="togas-container">
          <p class="text mobile-only">
            Nowadays, the localization of production is considered to be one of the best world practices. Its successful
            implementation depends on the highest quality standards.
          </p>
          <p class="text mobile-only">
            By combining our vast production experience and modern management methods, we develop and apply unified
            quality
            standards in all countries where excellent Togas products are produced.
          </p>
          <p class="text desktop-only">
            Nowadays, the localization of production is considered to be one of the best world practices. Its successful
            implementation depends on the highest quality standards. By combining our vast production experience and
            modern
            management methods, we develop and apply unified quality standards in all countries where excellent Togas
            products are produced.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import {
  SfHeading,
} from '~/components';

export default defineComponent({
  name: 'QualityControl',
  components: {
    SfHeading
  },
  setup() {


  },
});
</script>

<style lang="scss" scoped>

#quality-control {
  background-color: var(--togas-black-color);
  color: white;

  --heading-color: var(--togas-white-color);
  --heading-padding: var(--spacer-m);

  .black-block {
    height: 48px;
    background-color: var(--togas-black-color);
  }

  .title {
    font-family: var(--font-family--secondary);
    font-size: 28px;
    font-weight: 300;
    line-height: 25px;
    display: inline-block;
    margin-bottom: var(--spacer-base);
    text-transform: uppercase;

    @include for-desktop {
      font-size: 58px;
      line-height: 51px;
    }

    &--spacer {
      display: block;
      margin-left: 50px;
      font-style: italic;

      @include for-desktop {
        margin-left: 100px;
      }
    }
  }

  p {
    margin-left: 50px;

    @include for-desktop {
      margin-left: 0;
    }
  }

  .hero {
    height: auto;
    background-image: url("~static/quality-control/hero_mob.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 375 / 199;
    position: relative;

    --heading-margin: 0 0 var(--spacer-xs) 0;
    --heading-padding: var(--spacer-m) 0 0 0;

    @include for-desktop($mobile-max) {
      background-image: url("~static/quality-control/hero.jpg");
      background-position: center;
    }

    @include for-desktop {
      height: 780px;
      aspect-ratio: auto;

      --heading-margin: 0 0 var(--spacer-sm) 0;
      --heading-padding: var(--spacer-xl) 0 0 0;
    }

    .gradient {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 200px;
      background: linear-gradient(179.92deg, rgba(15, 19, 25, 0) 77.68%, #0F1319 99.93%);
    }
  }

  .subtitle {
    display: block;
    margin: 0 auto;
    max-width: 700px;
    font-family: var(--font-family--secondary);
    text-align: center;
    font-weight: 700;
    font-size: 36px;
    line-height: 32px;
    color: white;
    letter-spacing: -4px;

    @include for-desktop {
      font-size: 68px;
      line-height: 60px;
    }
  }

  .section {
    padding: 0 var(--spacer-sm);

    @include for-desktop {
      padding: 0 var(--spacer-xl);
    }
  }

  .traditions {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 40px var(--spacer-sm);

    @include for-desktop {
      flex-direction: row;
      gap: 80px;
      padding: 100px var(--spacer-xl);
    }

    &--text-block {
      color: white;

      @include for-desktop {
        max-width: 570px;
        width: 100%;
      }

      p {
        margin-left: 50px;

        @include for-desktop {
          width: 460px;
          margin-left: 100px;
        }
      }
    }

    &--image-block {
      position: relative;
      width: 100%;
      height: 307px;

      @include for-desktop {
        height: auto;
      }

      .tradition {
        @include for-mobile {
          position: absolute;
          z-index: 1;
        }

        @include for-desktop {
          width: 349px;
        }
      }

      .innovation {
        position: absolute;
        top: 120px;
        left: 50px;

        @include for-desktop {
          left: 250px;
          max-width: 375px;
          width: 100%;
        }
      }
    }
  }

  .quality {

    @include for-desktop {
      background-image: url("~static/quality-control/pen.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top;
      margin-bottom: 100px;
    }

    span {
      font-family: var(--font-family--secondary);
      font-weight: 200;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.03em;

      @include for-desktop {
        font-size: 20px;
        line-height: 24px;
      }
    }

    font-size: 14px;
    font-weight: 200;
    line-height: 15.72px;
    letter-spacing: 0.03em;
    text-align: left;

    .worldwide {
      margin: 0 0 var(--spacer-base) 0;

      @include for-desktop {
        margin: var(--spacer-base) 0;
      }
    }

    picture {
      display: flex;
      justify-content: center;

      @include for-mobile {
        height: 110px;
      }

      @include for-desktop {
        margin-top: 140px;
        margin-left: 100px;
        justify-content: flex-start;
      }
    }

    .togas-container {
      @include for-desktop {
        padding-bottom: 0;
      }
    }

    &--wrapper {

      @include for-desktop {
        max-width: 460px;
        margin-left: 100px;
      }
    }
  }

  .factory {
    --togas-content-padding: 0 var(--spacer-sm) var(--spacer-xl);

    @include for-desktop {
      --togas-content-padding: 0 var(--spacer-xl) 60px;
    }

    p {
      @include for-desktop {
        max-width: 760px;
        margin-left: 100px;
      }
    }

    &--text {
      font-family: var(--font-family--secondary);
      font-style: italic;
      font-size: 22px;
      line-height: 22px;
      display: block;
      margin-left: 50px;

      @include for-desktop {
        font-size: 36px;
        line-height: 36px;
        margin-left: 0;
      }
    }

    .common-text {
      @include for-mobile {
        margin-bottom: 20px;
      }

      @include for-desktop {
        font-size: 20px;
      }
    }
  }

  .greece, .standards {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @include for-desktop {
      justify-content: space-between;
      margin-bottom: 60px;
    }

    .image {
      background-image: url("~static/quality-control/greece.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 370px;
      width: 100%;

      @include for-desktop {
        height: 780px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: -24px;

      @include for-desktop {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: -54px;
      }
    }

    .image-block {
      display: flex;
      align-items: center;
      gap: 24px;

      @include for-desktop {
        gap: 32px;
      }

      .title {
        margin: 0;
      }
    }

    p {

      @include for-desktop {
        max-width: 460px;
      }
    }
  }

  .standards {

    .content {
      @include for-mobile {
        padding: 0 32px;
      }

      p {
        @include for-mobile {
          margin-bottom: 32px;
        }
      }
    }

    @include for-mobile {
      margin: 0 -16px;
    }

    .image {
      background-image: url("~static/quality-control/standarts.jpg");
    }
  }

  .pillow {

    .togas-container {
      padding-bottom: 0;
    }

    &--wrap {
      @include for-desktop {
        display: flex;
        justify-content: space-between;
        gap: 32px;
      }
    }

    &--text-wrap {
      margin-bottom: 32px;

      @include for-desktop {
        width: 100%;
        max-width: 467px;
      }
    }

    &--image-wrap {
      @include for-mobile {
        margin: 0 -16px;
      }

      @include for-desktop {
        order: -1;
        max-width: 700px;
        min-width: 500px;
        width: 100%;
        margin-top: 60px;
      }
    }

    .text {
      margin-bottom: 40px;

      @include for-desktop {
        margin-bottom: 42px;
      }
    }

    .signature {
      display: flex;

      @include for-desktop {
        flex-direction: column;
        gap: 16px;
      }

      &--wrap {
        display: flex;
        flex-direction: column;

        @include for-mobile() {
          margin-top: 20px;
        }

        @include for-desktop {
          flex-direction: row;
        }

        p {
          @include for-desktop {
            display: block;
            max-width: 320px;
          }
        }

        div {
          text-align: center;
        }

        img {
          min-width: 90px;
          height: 90px;

          @include for-desktop {
            min-width: 130px;
            height: 130px;
          }
        }

        span {
          @include for-desktop {
            font-size: 14px;
          }
        }
      }

      p {
        font-family: var(--font-family--secondary);
        font-size: 22px;
        font-style: italic;
        font-weight: 400;
        line-height: 22px;

        @include for-desktop {
          font-size: 36px;
          line-height: 36px;
        }
      }
    }
  }

  .map, .girls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 40px;

    @include for-desktop {
      flex-direction: row;
      margin-bottom: 60px;
    }

    .text-wrap {

      @include for-desktop {
        max-width: 460px;
        margin-left: 100px;
      }

      span {
        font-family: var(--font-family--secondary);
        font-style: italic;
        font-size: 22px;
        display: block;
        margin-left: 50px;

        @include for-desktop {
          font-size: 36px;
          line-height: 36px;
          margin-left: 0;
        }
      }
    }
  }

  .girls {

    @include for-mobile {
      gap: 0;
    }

    .text-wrap {
      @include for-mobile {
        order: -1;
      }
    }
  }

  .field {
    background-image: url("~static/quality-control/field-m.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 830px;

    @include for-desktop {
      background-image: url("~static/quality-control/field-d.jpg");
      height: 680px;
    }

    .gradient {
      background: linear-gradient(180deg, #0F1319 9.53%, rgba(15, 19, 25, 0) 75.95%);
    }

    .text {

      @include for-desktop {
        max-width: 660px;
        text-align: center;
        margin: 0 auto;
      }
    }
  }
}
</style>

